<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Home Care Organization Inspection Checklist
              </b-card-title>

              <p>This checklist is designed to assist you and your employees in preparing for an unannounced inspection. This checklist covers the areas reviewed by an analyst at the time of the visit. Please review this checklist to ensure the following items are updated and contained in the appropriate files. <strong>Personnel and administrative records must be maintained at the Home Care Organization and available for review by the analyst.</strong> Failure to do so may result in the assessment of civil penalties.</p>

              <validation-observer ref="simpleRules">
                <b-form>

                  <h4 class="text-center mt-2 mb-2">
                    POSTING
                  </h4>

                  <!-- Posting Table -->
                  <b-table
                    ref="itemsP"
                    :fields="fieldsP"
                    :items="itemsP"
                    responsive
                    title="Posting"
                  >

                    <!-- Column title -->
                    <template #cell(title)="data">
                      <b-form-group
                        class="min-width-300px mt-1rem"
                      >
                        <b-form-input
                          v-model="data.item.title"
                          type="text"
                          placeholder="Requirements"
                          disabled
                        />
                      </b-form-group>
                    </template>

                    <!-- Column Form Number -->
                    <template #cell(formNum)="data">
                      <b-form-group class="min-width-300px mt-1rem">
                        <b-form-input
                          v-model="data.item.formNum"
                          type="text"
                          rules="required"
                          placeholder="Form Number"
                        />
                      </b-form-group>
                    </template>

                    <!-- Column Code Section -->
                    <template #cell(codeSec)="data">
                      <b-form-group class="min-width-300px mt-1rem">
                        <b-form-input
                          v-model="data.item.codeSec"
                          type="text"
                          placeholder="Code Section"
                        />
                      </b-form-group>
                    </template>

                  </b-table>

                  <h4 class="text-center mt-2 mb-2">
                    PERSONNEL RECORDS
                  </h4>

                  <!-- Personnel Records Table -->
                  <b-table
                    ref="itemsPR"
                    :fields="fieldsPR"
                    :items="itemsPR"
                    responsive
                    title="Personnel Records"
                  >

                    <!-- Column Checkbox -->
                    <template #cell(text)="data">
                      <b-form-checkbox
                        v-model="data.item.value"
                        class="min-width-500px"
                        :value="data.item.text"
                        @change="getOption(data.item.id)"
                      >
                        {{ data.item.text }}
                        <div v-if="data.item.id === 2">
                          <div
                            v-for="subOptions in data.item.subOptions"
                            :key="subOptions.id"
                            class="mb-5"
                          >
                            <b-form-checkbox
                              v-model="subOptions.value"
                              :value="subOptions.text"
                              :disabled="disabledOption === 1"
                            >
                              {{ subOptions.text }}
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-form-checkbox>
                    </template>

                    <!-- Column Form Number -->
                    <template #cell(formNum)="data">
                      <b-form-group class="min-width-300px mt-1rem">
                        <div
                          v-for="subOptions in data.item.subOptions"
                          :key="subOptions.id"
                          class="mb-5"
                        >
                          <b-form-input
                            v-model="subOptions.formNum"
                            type="text"
                            placeholder="Form Number"
                          />
                        </div>
                      </b-form-group>
                    </template>

                    <!-- Column Code Section -->
                    <template #cell(codeSec)="data">
                      <b-form-group class="min-width-300px mt-1rem">
                        <div
                          v-for="subOptions in data.item.subOptions"
                          :key="subOptions.id"
                          class="mb-2"
                        >
                          <b-form-textarea
                            v-model="subOptions.codeSec"
                            type="text"
                            placeholder="Code Section"
                            rows="3"
                          />
                        </div>
                      </b-form-group>
                    </template>

                  </b-table>

                  <h4 class="text-center mt-2 mb-2">
                    ADMINISTRATIVE RECORDS
                  </h4>

                  <!-- Administrative Records Table -->
                  <b-table
                    ref="itemsAR"
                    :fields="fieldsAR"
                    :items="itemsAR"
                    responsive
                    title="Administrative Records"
                  >

                    <!-- Column Checkbox -->
                    <template #cell(text)="data">
                      <b-form-checkbox
                        v-model="data.item.value"
                        class="min-width-500px"
                        :value="data.item.text"
                      >
                        {{ data.item.text }}
                      </b-form-checkbox>
                    </template>

                    <!-- Column Form Number -->
                    <template #cell(formNum)="data">
                      <b-form-group class="min-width-300px mt-1rem">
                        <b-form-input
                          v-model="data.item.formNum"
                          type="text"
                          placeholder="Form Number"
                        />
                      </b-form-group>
                    </template>

                    <!-- Column Code Section -->
                    <template #cell(codeSec)="data">
                      <b-form-group class="min-width-300px mt-1rem">
                        <b-form-input
                          v-model="data.item.codeSec"
                          type="text"
                          placeholder="Code Section"
                        />
                      </b-form-group>
                    </template>

                  </b-table>

                  <h4 class="text-center mt-2 mb-2">
                    ADDITIONAL INFORMATION: APPLICATION DOCUMENTS
                  </h4>

                  <!-- Additional Information Table -->
                  <b-table
                    ref="itemsAI"
                    :fields="fieldsAI"
                    :items="itemsAI"
                    class="mb-0"
                    responsive
                    title="Additional Information"
                  >

                    <!-- Column Checkbox -->
                    <template #cell(text)="data">
                      <b-form-checkbox
                        v-model="data.item.value"
                        :value="data.item.text"
                      >
                        {{ data.item.text }}
                      </b-form-checkbox>
                    </template>

                    <!-- Column Form Number -->
                    <template #cell(formNum)="data">
                      <b-form-group class="min-width-300px mt-1rem">
                        <b-form-input
                          v-model="data.item.formNum"
                          type="text"
                          placeholder="Form Number"
                        />
                      </b-form-group>
                    </template>

                  </b-table>

                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr13/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click="validationForm()"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form12', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form14a', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BButton, VBToggle, BTable, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { required } from '@validations'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BButton,
    BTable,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,

    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr13'
    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })
    // const form = ref({})
    if (router.currentRoute.params.action === 'add') {
      // Do something
    }

    const form = ref({})

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      dateFormat: 'Y-m-d',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,
      flatPickrConfig,

      sock,
      sockId,
    }
  },
  data() {
    return {
      // static val
      id: this.$route.query.id,
      disabledOption: 1,
      action: this.$route.params.action,
      isNotSaved: false,
      forms: this.$route.name,
      formUpdateChanges: [],
      required,
      // Posting
      fieldsP: [
        {
          key: 'title',
          label: 'Requirement - The following items must be posted in a conspicuous location, visible both to clients and Affiliated Home Care Aides:',
          thClass: 'text-center align-middle',
        },
        {
          key: 'formNum',
          label: 'Form Number (If Any)',
          thClass: 'text-center align-middle',
        },
        {
          key: 'codeSec',
          label: 'California Health and Safety Code Section',
          thClass: 'text-center align-middle',
        },
      ],
      // Administrative Records
      fieldsPR: [
        {
          key: 'text',
          label: 'Requirement - The following documents must be kept in each licensee, employee, volunteer and Affiliated Home Care Aide’s file at the licensed Home Care Organization in which they are employed:',
          thClass: 'text-center align-middle',
        },
        {
          key: 'formNum',
          label: 'Form Number (If Any)',
          thClass: 'text-center align-middle',
        },
        {
          key: 'codeSec',
          label: 'California Health and Safety Code Section',
          thClass: 'text-center align-middle',
        },
      ],
      // Administrative Records
      fieldsAR: [
        {
          key: 'text',
          label: 'Requirement - The following administrative documents must be kept at each licensed Home Care Organization:',
          thClass: 'text-center align-middle',
        },
        {
          key: 'formNum',
          label: 'Form Number (If Any)',
          thClass: 'text-center align-middle',
        },
        {
          key: 'codeSec',
          label: 'California Health and Safety Code Section',
          thClass: 'text-center align-middle',
        },
      ],
      // Additional Information
      fieldsAI: [
        {
          key: 'text',
          label: 'Requirement - The following application documents shall be maintained by the licensed Home Care Organization and be complete, current and available for review:',
          thClass: 'text-center align-middle',
        },
        {
          key: 'formNum',
          label: 'Form Number (If Any)',
          thClass: 'text-center align-middle',
        },
      ],
      // Posting
      itemsP: [
        {
          title: 'Business hours',
          formNum: '',
          codeSec: '1796.42(a)',
        },
        {
          title: 'Home Care Organization license',
          formNum: '',
          codeSec: '1796.42(a)',
        },
      ],
      // Personnel Records
      itemsPR: [
        {
          id: 1,
          text: 'Personnel record',
          formNum: 'HCS 501',
          codeSec: '1796.37(a)(12)',
        },
        {
          id: 2,
          text: 'For all individuals who are required to fingerprint and who have contact with clients or access to confidential client information:',
          value: false,
          subOptions: [
            {
              id: 1,
              text: 'A signed statement regarding their criminal record history. If sending the original to the Department, a copy will be sufficient.',
              value: false,
              formNum: 'LIC 508',
              codeSec: '1796.23(a)\n1796.33\n1796.43(a)(1)',
            },
            {
              id: 2,
              text: 'Documentation of a criminal record clearance, criminal record exemption or transfer',
              value: false,
              formNum: '',
              codeSec: '1796.37(a)(12)',

            },
            {
              id: 3,
              text: 'All communication received from the Caregiver Background Check Bureau by the Home Care Organization licensee including criminal record exemption needed requests, approvals, denials, closures and rescissions.',
              value: false,
              formNum: '',
              codeSec: '1796.37(a)(12)',
            },
          ],
          formNum: 'LIC 508',
          codeSec: '1796.23(a)\n1796.33\n1796.43(a)(1)\n1796.37(a)(12)\n1796.37(a)(12)',
        },
        {
          id: 3,
          text: 'A signed statement acknowledging the requirement to report suspected or known dependent adult or elder abuse and suspected or known child abuse.',
          value: false,
          formNum: 'SOC 341A',
          codeSec: '1796.42(e)',
        },
        {
          id: 4,
          text: 'Training verification log and documentation of successful completion of training',
          value: false,
          formNum: 'HCS 500',
          codeSec: '1796.44',
        },
        {
          id: 5,
          text: 'TB clearance',
          value: false,
          formNum: '',
          codeSec: '1796.45',
        },
        {
          id: 6,
          text: 'All communication received pertaining to the Affiliated Home Care Aide’s registration on the Home Care Aide Registry including, but not limited to, approvals, denials, revocations and forfeitures.',
          value: false,
          formNum: '',
          codeSec: '1796.37(a)(12)',
        },
      ],
      // Administrative Records
      itemsAR: [
        {
          id: 1,
          text: 'Certificate of insurance for a valid workers’ compensation policy covering Affiliated Home Care Aides',
          value: false,
          formNum: '',
          codeSec: '1796.42(b)',
        },
        {
          id: 2,
          text: 'Valid employee dishonesty bond, including third-party coverage, with a minimum limit of ten thousand dollars ($10,000)',
          value: false,
          formNum: 'HCS 402',
          codeSec: '1796.42(c)',
        },
        {
          id: 3,
          text: 'Certificate of insurance for a general and professional liability insurance policy in the amount of at least one million dollars ($1,000,000) per occurrence and three million dollars ($3,000,000) in the aggregate',
          value: false,
          formNum: '',
          codeSec: '1796.42(d)',
        },
        {
          id: 4,
          text: 'Documentation from the Department of any waivers and exceptions (if applicable)',
          value: false,
          formNum: 'HCS 971',
          codeSec: '1796.37(a)(12)',
        },
        {
          id: 5,
          text: 'Proof of completion of the Department orientation',
          value: false,
          formNum: 'HCS 278',
          codeSec: '1796.37(a)(7)',
        },
        {
          id: 6,
          text: 'Suspected abuse reports (if applicable)',
          value: false,
          formNum: 'SOC 341',
          codeSec: '1796.42(e)',
        },
      ],
      // Additional Information
      itemsAI: [
        {
          id: 1,
          text: 'Application For a Home Care Organization License',
          value: false,
          formNum: 'HCS 200',
        },
        {
          id: 2,
          text: 'Licensee Applicant Information',
          value: false,
          formNum: 'HCS 215',
        },
        {
          id: 3,
          text: 'Designation of Home Care Organization Responsibility',
          value: false,
          formNum: 'HCS 308',
        },
        {
          id: 4,
          text: 'Partnership/Corporation/Limited Liability Company Organization Structure',
          value: false,
          formNum: 'HCS 309',
        },
        {
          id: 5,
          text: 'Board of Directors’ Statement',
          value: false,
          formNum: 'HCS 9165',
        },
        {
          id: 6,
          text: 'Partnership Agreement/Articles of Incorporation/Articles of Organization',
          value: false,
          formNum: '',
        },
        {
          id: 7,
          text: 'Program Description - A general over view of the program and services provided',
          value: false,
          formNum: '',
        },
        {
          id: 8,
          text: 'Job Description(s) - Each Position',
          value: false,
          formNum: '',
        },
        {
          id: 9,
          text: 'Personnel Policies',
          value: false,
          formNum: '',
        },
        {
          id: 10,
          text: 'Affiliated Home Care Aide Training Plan',
          value: false,
          formNum: '',
        },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        // if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
        let valCopy = this.formCopy[key]
        if (Array.isArray(this.formCopy[key])) {
          valCopy = JSON.stringify(this.formCopy[key])
        }
        // console.log(key, val, valCopy, val !== valCopy)
        if (val !== valCopy) {
          try {
            this.formUpdateChanges.push(this.$refs[key].$attrs.title)
          } catch (error) {
            console.error(error)
          }
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }

    console.log(this.itemsPR[1].value)
    if ((this.$route.params.action === 'edit') && this.$route.query.id !== null) {
      this.fetchForm13()
    }
    if (this.$route.params.action === 'add' && this.$route.query.id !== null) {
      this.fetchCaregiverData()
      this.fetchForm13()
    }
    if (this.$route.params.action === 'add') {
      this.fetchCaregiverData()
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr13/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr13/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          const itemPR = this.itemsPR
          const itemAR = this.itemsAR
          const itemAI = this.itemsAI

          this.itemsP = JSON.parse(jsonObjectLiterals.data.itemsP)
          this.itemsPR = JSON.parse(jsonObjectLiterals.data.itemsPR)
          this.itemsAI = JSON.parse(jsonObjectLiterals.data.itemsAI)
          this.itemsAR = JSON.parse(jsonObjectLiterals.data.itemsAR)

          this.itemsPR[5].text = itemPR[5].text
          this.itemsAR[0].text = itemAR[0].text
          this.itemsAI[4].text = itemAI[4].text
          if (this.itemsPR[1].value === 'For all individuals who are required to fingerprint and who have contact with clients or access to confidential client information:') {
            this.disabledOption = true
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr13/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr13/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    saveForm() {
      const self = this
      const P = this.itemsP
      const PR = this.itemsPR
      const AI = this.itemsAI
      const AR = this.itemsAR

      const formData = {
        itemsP: P,
        itemsPR: PR,
        itemsAI: AI,
        itemsAR: AR,
        cid: this.id,
        id: this.form.id,
      }

      this.form.itemsP = this.itemsP
      this.form.itemsPR = this.itemsPR
      this.form.itemsAI = this.itemsAI
      this.form.itemsAR = this.itemsAR

      // console.log(JSON.stringify(this.form), JSON.stringify(this.formCopy), JSON.stringify(this.form) !== JSON.stringify(this.formCopy))
      let msg = 'Do you want to save the changes?'
      if (this.action === 'add') msg = 'Do you want to add these data?'
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: msg,
        confirmButtonText: 'Proceed',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark mr-1',
        },
        buttonsStyling: false,
        returnFocus: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.isNotSaved = true
          store
            .dispatch(this.action === 'add' ? 'app-hr13/addForm13' : 'app-hr13/updateForm13', formData)
            .then(response => {
              // eslint-disable-next-line
              const jsonObjectLiterals = response.data.response.data

              this.form.id = jsonObjectLiterals.id

              if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                self.requestFormUpdate()
              }

              Swal.fire({
                icon: 'success',
                // eslint-disable-next-line
                title: 'Saved!',
                text: 'Saved succesfully.',
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1500,
              })
              console.error(error)
            })
            .finally(() => {
              self.isNotSaved = false
            })
        }
      })
    },
    fetchForm13() {
      store
        .dispatch('app-hr13/fetchForm13', { id: this.id })
        .then(response => {
          const data = response.data.response.data[0]
          const obj = JSON.parse(data.forms)

          const itemPR = this.itemsPR
          const itemAR = this.itemsAR
          const itemAI = this.itemsAI

          if (obj) {
            this.itemsP = obj.itemsP
            this.itemsPR = obj.itemsPR
            this.itemsAI = obj.itemsAI
            this.itemsAR = obj.itemsAR

            this.itemsPR[5].text = itemPR[5].text
            this.itemsAR[0].text = itemAR[0].text
            this.itemsAI[4].text = itemAI[4].text
            if (this.itemsPR[1].value === 'For all individuals who are required to fingerprint and who have contact with clients or access to confidential client information:') {
              this.disabledOption = true
            }
          }

          this.form = obj

          this.form.id = JSON.stringify(data.id)

          this.form.cid = JSON.stringify(data.cid)

          if (this.form.cid) {
            this.form.form = 'folder4_form0'

            this.formCopy = JSON.parse(JSON.stringify(this.form))

            store.dispatch('app-hr13/fetchFormUpdate', { form: this.form.form, form_id: this.form.cid })
              .then(response2 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response2.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.status) {
                  this.formUpdate = jsonObjectLiterals.data

                  this.formUpdateStatus = this.formUpdate.status
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchCaregiverData() {
      store
        .dispatch('app-hr13/fetchCaregiverData', { id: this.id })
        .then(response => {
          console.log(response)
          // eslint-disable-next-line
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.saveForm()
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    getOption(id) {
      if (id === 2) {
        this.disabledOption = (this.disabledOption + 1) % 2
      }

      if (this.disabledOption === 1) {
        this.itemsPR[1].subOptions[0].value = false
        this.itemsPR[1].subOptions[1].value = false
        this.itemsPR[1].subOptions[2].value = false
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.min-width-300px
{
  min-width: 300px;
}

.min-width-500px
{
  min-width: 500px;
}

.mt-1rem {
  margin-top: 1rem;
}
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
